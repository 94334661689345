:root {
  // --ct-primary: #131528;
  --ct-primary: #727cf5;

  --ct-success-bg-subtle: #e7faf5;
  --ct-primary-bg-subtle: #f1f2fe;
  --ct-body-color: #6c757d;
  --ct-font-sans-serif: "Nunito", sans-serif;
  --ct-primary-rgb: 114, 124, 245;
  --ct-success-rgb: 10, 207, 151;

  --ct-theme-card-border-width: 0px;

  --ct-body-font-family: var(--ct-font-sans-serif);
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-color-rgb: 108, 117, 125;
  --ct-body-bg: #fafbfe;
  --ct-body-bg-rgb: 250, 251, 254;

  --ct-secondary-color: rgba(108, 117, 125, 0.75);
  --ct-secondary-bg: #fff;

  --ct-danger-rgb: 250, 92, 124;

  --ct-heading-color: inherit;

  --ct-border-width: 1px;
  --ct-border-style: solid;
  --ct-border-color: #dee2e6;
  --ct-border-color-translucent: #dee2e6;
  --ct-border-radius: 0.25rem;
  --ct-border-radius-sm: 0.2rem;
  --ct-border-radius-lg: 0.3rem;
  --ct-border-radius-xl: 1rem;
  --ct-border-radius-xxl: 2rem;
  --ct-border-radius-2xl: var(--ct-border-radius-xxl);
  --ct-border-radius-pill: 50rem;

  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(var(--ct-body-color-rgb), 0.075);

  --ct-gray-dark: #343a40;
  --ct-gray-100: #f6f7fb;
  --ct-gray-200: #eef2f7;
  --ct-gray-300: #dee2e6;
  --ct-gray-400: #ced4da;
  --ct-gray-500: #a1a9b1;
  --ct-gray-600: #8a969c;
  --ct-gray-700: #6c757d;
  --ct-gray-800: #343a40;
  --ct-gray-900: #313a46;
}

body {
  font-family: var(--ct-body-font-family);
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  background-color: var(--ct-body-bg);
}

.hero-section {
  position: relative;
  padding: 80px 0 120px 0;
}

.hero-section-after:after {
  content: " ";
  background-image: linear-gradient(to bottom, #131528, #546ee5);
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  transform: skewY(-3deg);
}

.hero-section .border-white-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--ct-primary);
  color: var(--ct-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-primary-lighten {
  background-color: var(--ct-primary-bg-subtle) !important;
}

.bg-success-lighten {
  background-color: var(--ct-success-bg-subtle) !important;
}

.text-muted {
  --ct-text-opacity: 1;
  color: var(--ct-secondary-color) !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 10px;
}

.h3,
h3 {
  font-size: calc(1.2753rem + 0.3036vw);
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.1;
  color: var(--ct-heading-color);
}

.font-16 {
  font-size: 16px !important;
}

.btn {
  --ct-btn-padding-x: 0.9rem;
  --ct-btn-padding-y: 0.45rem;
  --ct-btn-font-size: 0.9rem;
  --ct-btn-font-weight: 400;
  --ct-btn-line-height: 1.5;
  --ct-btn-color: var(--ct-body-color);
  --ct-btn-bg: transparent;
  --ct-btn-border-width: var(--ct-border-width);
  --ct-btn-border-color: transparent;
  --ct-btn-border-radius: 0.15rem;
  --ct-btn-hover-border-color: transparent;
  --ct-btn-box-shadow: 0px 2px 6px 0px;
  --ct-btn-disabled-opacity: 0.65;
  --ct-btn-focus-box-shadow: 0 0 0 0.15rem
    rgba(var(--ct-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--ct-btn-padding-y) var(--ct-btn-padding-x);
  font-family: var(--ct-btn-font-family);
  font-size: var(--ct-btn-font-size);
  font-weight: var(--ct-btn-font-weight);
  line-height: var(--ct-btn-line-height);
  color: var(--ct-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--ct-btn-border-width) solid var(--ct-btn-border-color);
  border-radius: var(--ct-btn-border-radius);
  background-color: var(--ct-btn-bg);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn-outline-light {
  --ct-btn-color: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #eef2f7;
  --ct-btn-hover-border-color: #eef2f7;
  --ct-btn-focus-shadow-rgb: 238, 242, 247;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #eef2f7;
  --ct-btn-active-border-color: #eef2f7;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #eef2f7;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #eef2f7;
  --ct-gradient: none;
}

.btn-light {
  --ct-btn-color: #313a46;
  --ct-btn-bg: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #dce0e4;
  --ct-btn-hover-border-color: #dce0e4;
  --ct-btn-focus-shadow-rgb: 210, 214, 220;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #dce0e4;
  --ct-btn-active-border-color: #dce0e4;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #313a46;
  --ct-btn-disabled-bg: #eef2f7;
  --ct-btn-disabled-border-color: #eef2f7;
}

.btn-info {
  --ct-btn-color: #fff;
  --ct-btn-bg: #39afd1;
  --ct-btn-border-color: #39afd1;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #35a2c1;
  --ct-btn-hover-border-color: #35a2c1;
  --ct-btn-focus-shadow-rgb: 87, 187, 216;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #35a2c1;
  --ct-btn-active-border-color: #35a2c1;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #39afd1;
  --ct-btn-disabled-border-color: #39afd1;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid var(--ct-gray-500);
  border-radius: 50%;
  color: var(--ct-gray-500);
}

.text-primary {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-primary-rgb), var(--ct-text-opacity)) !important;
}

.text-danger {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-danger-rgb), var(--ct-text-opacity)) !important;
}

.text-success {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-success-rgb), var(--ct-text-opacity)) !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.text-white-50 {
  --ct-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

#myVideo {
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: auto;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 1080px;
  object-fit: cover;
}

.video-load {
  opacity: 0.6 !important;
  transition: opacity 1s ease-in;
}

.video-box {
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  background-color: #5057a2;
  height: 1080px;
}

.fade-in {
  animation: opacity 1s ease-in;
}

.card {
  --ct-card-spacer-y: 1.5rem;
  --ct-card-spacer-x: 1.5rem;
  --ct-card-title-spacer-y: 0.75rem;
  --ct-card-border-width: var(--ct-theme-card-border-width);
  --ct-card-border-color: var(--ct-border-color);
  --ct-card-border-radius: var(--ct-border-radius);
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-inner-border-radius: calc(
    var(--ct-border-radius) - (var(--ct-theme-card-border-width))
  );
  --ct-card-cap-padding-y: 1.125rem;
  --ct-card-cap-padding-x: 1.5rem;
  --ct-card-cap-bg: transparent;
  --ct-card-bg: var(--ct-secondary-bg);
  --ct-card-img-overlay-padding: 1.5rem;
  --ct-card-group-margin: 0.75rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  height: var(--ct-card-height);
  color: var(--ct-body-color);
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: var(--ct-card-border-width) solid var(--ct-card-border-color);
  border-radius: var(--ct-card-border-radius);

  -webkit-box-shadow: var(--ct-box-shadow);
  box-shadow: var(--ct-box-shadow);
  margin-bottom: 1.5rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: var(--ct-card-spacer-y) var(--ct-card-spacer-x);
  color: var(--ct-card-color);
}
